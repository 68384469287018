/***************************************************************
*  PROGRAMOWANIE.pro
***************************************************************/


'use strict';



$(function () {


// HELPERS
// ==========================================================

    let animationWorking = false;


    $('body').attr('vh', document.documentElement.clientHeight);
    $('.section--home').css('height', document.documentElement.clientHeight);

    function cc(what) {
        console.log(what);
    }

    $.expr[':'].contains = function (a, i, m) {
        return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };

    let getWH = function (value) { // get width and height
        let w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
        let b = $('body');
        b.attr('data-width', x);
        b.attr('data-height', y);
        if (value === "width") {
            return parseInt(x);
        } else {
            return parseInt(y);
        }
    }

// LOCALSTORAGE
// ==========================================================

    let localS = {
        save: function (name, value) {
            localStorage.setItem(name, value);
        },
        get: function (name) {
            let getName = localStorage.getItem(name);
            return getName;

        },
        remove: function (name) {
            localStorage.getItem(name);
        }
    }


    // scrollMe
    // ==========================================================

    function scrollMe(target, time, delay, callback) {
        let timer;

        if (!target.length) {
            return false;
        }

        if (!animationWorking) {
            animationWorking = true;
            $('html, body').stop().animate({
                scrollTop: $(target).offset().top - 100
            }, time, function () {
            }).promise().then(function () {
                animationWorking = false;
            });
        }
    }


    // FORM: Ajax
    // ==========================================================

    let formSend = function () {

        $(document).on('submit', ".js__form-apply", function (e) {
            e.preventDefault();

            let $self = $(this),
                postData = new FormData(this),
                formURL = $self.attr("action");

            $.ajax({
                url: formURL,
                type: 'POST',
                data: postData,
                processData: false,
                contentType: false,
                success: function (data, textStatus, jqXHR) {
                    $('.form-apply__content').slideUp(400);
                    $('.form-apply__msg').removeClass('hide');
                    $('.form-apply__error').hide();
                    // reOpen();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // $('.form-apply__content').slideUp(400);
                    $('.form-apply__error').removeClass('hide');
                }
            });
        });
    };

    // SCROLLACTIVE ELEMENTS for Animation
    // ==========================================================

    $('.nav-top__link').on('click', function (e) {
        e.preventDefault();
        let go = $(this).data('id');
        let section = $('#' + go);

        $('body').removeClass('menu--active');

        scrollMe(section, 900, 0);
    });


    // Menu RWD
    // ==========================================================

    function rwdMenu() {
        let $burger = $('.menu-burger__bg');

        $burger.on('click', function (e) {
            e.preventDefault();
            let $body = $('body');
            $body.toggleClass('menu--active');
        });

    };


    // SmallChanges
    // ==========================================================

    $('.js__scroll').on('click', function (e) {
        e.preventDefault();
        let goTo = $(this).attr('href');

        scrollMe(goTo, 900);
    });

    $('.js__toggle').on('click', function (e) {
        let target = $(this).data('target-id');
        
        if ($(this).hasClass('active')) {
            $('#' + target).slideUp(600);
        } else {
            $('#' + target).slideDown(600);
        }
        
        $(this).toggleClass('active');
    });



    // INIT FUNCTIONS
    // ==========================================================

    function init() {
        let current = $(window).scrollTop();
        let eventTimeout;
        getWH();
        rwdMenu();
        formSend();

        $('body').addClass('body--animation-start');

        function eventsOnScroll() {
            if (!eventTimeout) {
                eventTimeout = setTimeout(function () {
                    eventTimeout = null;
                }, 250);
            }
        };

        $(window).on('resize', function () {
            $('body').attr('vh', document.documentElement.clientHeight);
            $('.section--home').css('height', document.documentElement.clientHeight);
        });
        $(window).on('scroll', function () {
            current = $(window).scrollTop();
            eventsOnScroll(current);
        });
    };

    init();

});


window.onload = function () {
    
    function scrollObserver() {
        let observer;
        let observerB;

        let config = {
            threshold: [0, 0.5, 0.75, 1],
            rootMargin: "0px 0px -50% 0px"
        };

        function obsSections($id) {
            $('#output').text($id);
            $('.section--obs').not($('#' + $id)).removeClass('section--active');
            $('.nav-top__link').removeClass('item--active');

            $('#' + $id).addClass('section--active section--visited');
            $('.nav-top__link[data-id="' + $id + '"]').addClass('item--active');
        }

        function obsAnime(el) {
            $('.section--active [class*=animation]').addClass('animation--active');
        }

        let timeDelay;


            const sections = document.querySelectorAll('.section--obs');
            const anime = document.querySelectorAll('.section--active [class*=animation]');
        
            observer = new IntersectionObserver(
                (entries) => {
                    for (let entry of entries)
                        if (entry.isIntersecting && entry.target.id !== '')
                            obsSections(entry.target.id)
                },
                {
                    rootMargin: "-50% 0px",
                }
            );
            for (let i = 0; i < sections.length; i++) observer.observe(sections[i]);

    }
    scrollObserver();

    let gmap = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d18527.571840214558!2d18.539934849999998!3d54.51680525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1642132378118!5m2!1spl!2spl" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>';
    if ($('body').data('width') > 1024) {
        $(gmap).appendTo('#iframe');
    }
};